import { useState, useEffect, useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import { getBuyer, joinLive } from "../axios/api";
import AgoraRTC from "agora-rtc-sdk-ng";
import { options, rtc } from "../constant";
import { Button, TextField, Grid2, IconButton } from "@mui/material";
import io from "socket.io-client";
import SendIcon from '@mui/icons-material/Send';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ProductGrid from "../components/productDisplay";
import goliveAnimation from "../components/lottie/live.json"
import Lottie from "lottie-react";

const JoinLive = () => {
    const [liveData, setLiveData] = useState();
    const [streamMuted, setStreamMuted] = useState(false);
    const [comment, setComment] = useState('');
    const [comments, setComments] = useState([]);
    const location = useLocation();
    const { id } = useParams();
    const [token, setToken] = useState('');
    const [numUsers, setNumUsers] = useState(0);
    const [loading ,setLoading] = useState(true)
    const [showEndLiveButton, setShowEndLiveButton] = useState(false);
    const remoteAudioTracks = new Map();
    const commentsEndRef = useRef(null);
    const [isUserScrolling, setIsUserScrolling] = useState(false);
    const [userInfo, setUserInfo] = useState()

    const [socket, setSocket] = useState(null);

    useEffect(() => {
        if (userInfo) {
            const socketConnection = io(process.env.REACT_APP_SOCKET_URL);
            setSocket(socketConnection)
            socketConnection.on("connect", () => {
                console.log("Connected to socket server");
                socketConnection.emit('join_stream', { streamId: id, userId: userInfo._id });
            });

            socketConnection.on("receive_comments", (comments) => {
                console.log("Received previous comments:", comments);
                setComments(comments);
            });
            socketConnection.on("user_count", (count) => {
                console.log("User count updated:", count);
                setNumUsers(count);
            });


            socketConnection.on("receive_comment", (newComment) => {
                console.log("Received new comment:", newComment);
                setComments((prevComments) => [...prevComments, newComment]);
            });

            return () => {
                console.log("Disconnecting from socket server...");
                socketConnection.disconnect();
            };
        }
    }, [id, userInfo]);


    useEffect(() => {
        const commentsContainer = document.getElementById("comments");
    
        const handleScroll = () => {
            if (!commentsContainer) return;
            
            const isAtBottom =
                commentsContainer.scrollHeight - commentsContainer.scrollTop <=
                commentsContainer.clientHeight + 10; // Allow some tolerance
            
            setIsUserScrolling(!isAtBottom);
        };
    
        if (commentsContainer) {
            commentsContainer.addEventListener("scroll", handleScroll);
        }
    
        return () => {
            if (commentsContainer) {
                commentsContainer.removeEventListener("scroll", handleScroll);
            }
        };
    }, []);
    
    useEffect(() => {
        if (!isUserScrolling && commentsEndRef.current) {
            commentsEndRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
        }
    }, [comments]);



    const handleJoinLive = async () => {
        if (!liveData || !liveData.streamId) {
            console.error("Missing live data or token");
            return;
        }

        try {
            await rtc.client.join(options.appId, liveData.streamId, liveData.token, liveData.uid);

            rtc.client.on("user-published", async (user, mediaType) => {
                console.log("Remote user published:", user.uid);

                await rtc.client.subscribe(user, mediaType);

                if (mediaType === "video" || mediaType === "all") {
                    const remoteVideoTrack = user.videoTrack;
                    if (remoteVideoTrack) {
                        console.log("Playing remote video track for UID:", user.uid);
                        const playerContainer = document.createElement("div");
                        playerContainer.id = `remote-${user.uid}`;
                        playerContainer.classList.add("remote-player");
                        playerContainer.style.width = "100%";
                        playerContainer.style.height = "100%";
                        document.getElementById("remote-stream").appendChild(playerContainer);
                        remoteVideoTrack.play(playerContainer.id);
                    }
                }

                if (mediaType === "audio" || mediaType === "all") {
                    const remoteAudioTrack = user.audioTrack;
                    remoteAudioTracks.set(user.uid, remoteAudioTrack);
                    if (!streamMuted) {
                        remoteAudioTrack.play();
                    }
                }
            });

            rtc.client.on("user-unpublished", (user) => {
                console.log("Remote user unpublished:", user.uid);
                remoteAudioTracks.delete(user.uid);
                const playerContainer = document.getElementById(`remote-${user.uid}`);
                if (playerContainer) {
                    playerContainer.remove();
                }
            });
        } catch (error) {
            console.log(error);
        }
    };

    const handleLeave = async () => {
        try {
            const localContainer = document.getElementById("remote-stream");
            if (localContainer) {
                localContainer.innerHTML = "";
            }

            rtc.client.remoteUsers.forEach((user) => {
                const playerContainer = document.getElementById(`remote-${user.uid}`);
                if (playerContainer) {
                    playerContainer.remove();
                }
            });

            await rtc.client.leave();
            socket.emit('leave_stream', { streamId: id, userId: userInfo._id });
            window.close();
            console.log("Left the channel successfully");
        } catch (error) {
            console.error("Error leaving channel:", error);
        }
    };

    const fetchData = async (id) => {
        try {
            const response = await joinLive(id);
            if (response.data) {
                setLiveData(response.data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleSendComment = () => {
        if (!comment.trim()) return;

        socket.emit("send_comment", {
            comment,
            user: userInfo.name,
            timestamp: new Date().toLocaleTimeString(),
            streamId: id,
        });

        setComment('');
    };

    const fetchBuyerInfo = async () => {
        try {
            const response = await getBuyer()
            if (response.data) {
                setUserInfo(response.data)
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchBuyerInfo()
    }, [])

    useEffect(() => {
        if (commentsEndRef.current) {
            commentsEndRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
        }
    }, [comments]);

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const tokenFromUrl = urlParams.get('token');
        if (tokenFromUrl) {
            localStorage.setItem("token", tokenFromUrl);
        }
        setToken(tokenFromUrl);
    }, [location.search]);

    useEffect(() => {
        if (id && token) {
            fetchData(id);
        }
    }, [id, token]);

    useEffect(() => {
        if (liveData) {
            rtc.client = AgoraRTC.createClient({ mode: "rtc", codec: "h264" });
            handleJoinLive();
        }
    }, [liveData]);


    useEffect(() => {
        if(loading && id && token && liveData ){
          setLoading(false)
        }
      },[loading , id ,token , liveData])
    


      
    
      const styles = {
        loaderContainer: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh', 
          backgroundColor: 'black', 
          color: 'white',  
          textAlign: 'center', 
        },
        message: {
          marginTop: '20px',  
          fontSize: '20px', 
          fontWeight: 'bold',  
        }
      };
      
    
    
      if (loading) {
        return (
          <div style={styles.loaderContainer}>
            <Lottie 
              animationData={goliveAnimation} 
              loop={true}  // Set loop to true to keep playing
              autoplay={true}  // Ensures the animation starts automatically
              style={{ height: "200px" }} 
            />
             <p style={styles.message}>Joining Live Stream...</p>
          </div>
        );
      }

    return (
        <div style={{ width: "100%", height: "100vh", background: "black", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Grid2 className="stream-block-main" container spacing={1} style={{ width: "100%", height: "100%" }}>

                {/* First Grid: Product Display (Scrollable Content) */}
                <Grid2
                    item
                    size={{ xs: 12, sm: 4, md: 4 }}
                    style={{
                        backgroundColor: 'transparent',
                        height: '100%',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                    }}
                    sx={{
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                        scrollbarWidth: 'none',
                    }}
                >
                    <ProductGrid products={liveData?.stream?.products} />
                </Grid2>

                {/* Second Grid: Video/Stream Section */}
                <Grid2 className="live-stream-block"
                    item
                    size={{ xs: 12, sm: 4, md: 4 }}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                        height: '100%',
                    }}
                >
                    <div
                        id="remote-stream"
                        style={{
                            width: "100%",
                            maxWidth: "100%",
                            height: "100%",
                            borderRadius: '8px',
                            backgroundColor: "black",
                            position: "relative",
                            overflow: "hidden",
                        }}
                        onMouseEnter={() => setShowEndLiveButton(true)}
                        onMouseLeave={() => setShowEndLiveButton(false)}
                    >
                        <div style={{
                            position: "absolute",
                            top: 10,
                            left: 10,
                            color: 'white',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            zIndex: "999",
                            borderRadius: "50px",
                        }}>
                            <img src={liveData?.stream.sellerId?.profileImage} alt='profile' height={45} width={45} style={{ borderRadius: "50px" }} />
                        </div>
                        <div
                            style={{
                                position: "absolute",
                                top: 10,
                                right: 10,
                                color: 'white',
                                zIndex: "999",
                                borderRadius: "50px",
                                background: "pink",
                                padding: "5px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: "pointer",
                                '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.7)' },
                            }}
                        >
                            <VisibilityIcon sx={{ color: "#e1306c", fontSize: "30px" }} />
                            {numUsers && <span style={{ marginLeft: "5px", fontSize: "16px", color: "#333" }}>{numUsers - 1}</span>}
                        </div>
                    </div>

                    <Button
                        onClick={handleLeave}
                        variant="contained"
                        color="error"
                        sx={{
                            position: 'absolute',
                            bottom: 10,
                            left: '50%',
                            transform: 'translateX(-50%)',
                            zIndex: 999,
                            backgroundColor: 'rgba(255, 0, 0, 0.7)',
                            '&:hover': { backgroundColor: 'rgba(255, 0, 0, 0.9)' }
                        }}
                    >
                        Leave
                    </Button>
                </Grid2>

                <Grid2
                    item
                    size={{ xs: 12, sm: 4, md: 4 }}
                    style={{
                        height: "100vh",
                        padding: "20px",
                        overflowY: "auto",
                        overflowX: 'hidden',
                        backgroundColor: "#f4f4f4",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-end",
                        borderRadius:"10px"
                    }}
                    sx={{
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                        scrollbarWidth: 'none',
                    }}
                >
                    <div
                        id="comments"
                        style={{
                            maxHeight: '80%',
                            overflowY: 'scroll',
                            width: '100%',
                            marginBottom: '20px',
                            flex: 1,
                            paddingRight: '10px',
                        }}
                    >
                        {comments.map((commentData, index) => (
                            <div key={index}
                                style={{
                                    padding: '15px',
                                    marginBottom: '10px',
                                    backgroundColor: '#ffffff',
                                    borderRadius: '8px',
                                    border: '1px solid #ddd',
                                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                                    maxWidth: '90%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                }}>
                                <strong>{commentData.user}</strong>
                                <p>{commentData.comment}</p>
                            </div>
                        ))}
                        <div ref={commentsEndRef} />
                    </div>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingTop: "10px",
                        }}
                    >
                        <TextField
                            label="Type your comment"
                            variant="outlined"
                            fullWidth
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    e.preventDefault();
                                    handleSendComment();
                                }
                            }}
                            sx={{
                                marginRight: "10px",
                                flex: 1,
                            }}
                        />
                        <IconButton
                            onClick={handleSendComment}
                            sx={{
                                backgroundColor: "rgba(0, 0, 255, 0.7)",
                                "&:hover": { backgroundColor: "rgba(0, 0, 255, 0.9)" },
                            }}
                        >
                            <SendIcon sx={{ color: "white" }} />
                        </IconButton>
                    </div>
                </Grid2>

            </Grid2>

        </div>
    );
};

export default JoinLive;

