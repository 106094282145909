import './App.css'
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Golive from './routes/live';
import JoinLive from './routes/joinLive';

function App() {

  return (
    <>
  <Router>
    <Routes>
      <Route path='/live/:id' element={<Golive/>} />
      <Route path='/join-live/:id' element={<JoinLive/>}/>
    </Routes>
  </Router>
    </>
  )
}

export default App
