import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Button,
    Card,
    CardMedia,
    CardContent,
    Chip,
    Divider,
    RadioGroup,
    FormControlLabel,
    Radio
} from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { checkVariantExist } from '../axios/api';

const ProductDisplayPanel = ({ product }) => {
    const [selectedAttributes, setSelectedAttributes] = useState({});
    const [isVariantAvailable, setIsVariantAvailable] = useState(false);
    const [variantId , setVairantId] = useState("")
    const [price, setPrice] = useState(0)
    const [totalQuantity, setTotalQuantity] = useState(0)
    const [discountPrice, setDiscountPrice] = useState(0)
    const [quantity, setQuantity] = useState(1);
    

    const handleAttributeSelect = (name, value) => {
        const newAttributes = { ...selectedAttributes, [name]: value };
        setSelectedAttributes(newAttributes);

        checkVariantAvailability(newAttributes);
    };


    const createVariantString = (attributes) => {
        let variantString = "";

        Object.keys(attributes).forEach((key, index) => {
            variantString += `${key}-${attributes[key]}`;

            if (index !== Object.keys(attributes).length - 1) {
                variantString += ",";
            }
        });

        return variantString;
    };

    const checkVariantAvailability = async (selectedAttributes) => {
        console.log(selectedAttributes, "dfdsfsdfsd");

        const variantString = createVariantString(selectedAttributes);
        console.log(variantString)
        const response = await checkVariantExist({ productId: product._id, variant: variantString })
        if (response.data.exists) {
            const variantIndex = product?.variants?.findIndex((val) => val._id === response?.data?.id)
            console.log(variantIndex);
            setPrice(product?.variants[variantIndex]?.price)
            setDiscountPrice(product?.variants[variantIndex]?.discountPrice)
            setIsVariantAvailable(response.data.exists)
            setTotalQuantity(product?.variants[variantIndex]?.quantity)
        }
        else {
            setIsVariantAvailable(false)
            setVairantId("")
            setTotalQuantity(0)
          }

    };

    useEffect(() => {
        if (product?.type === "simple") {
          setPrice(Number(product?.price));
          setTotalQuantity(Number(product?.quantity) ?? 0)
          setDiscountPrice(Number(product?.discountPrice) ?? 0)
          setIsVariantAvailable(true)
        }
        if (product && product?.attributes && product?.attributes?.length > 0 && product?.variants && product?.variants?.length > 0 && product?.type === "variant") {
            const initialAttributes = {};

            product?.variants[0]?.variant?.forEach((variant) => {
                initialAttributes[variant.name] = variant.value;
            });
            setSelectedAttributes(initialAttributes)
          setVairantId(product?.variants[0]?._id)
          setPrice(product?.variants[0]?.price)
          setDiscountPrice(product?.variants[0]?.discountPrice)
          setTotalQuantity(product?.variants[0]?.quantity)
          setIsVariantAvailable(true)
        }
      }, [product])

    return (
        <Card elevation={0} sx={{  m: 2 ,minWidth:"400px" }}>
            <CardMedia
                component="img"
                height="200"
                image={product.productImages[0] || "https://via.placeholder.com/300x200?text=Product+Image"}
                alt={product.title}
                sx={{ objectFit: 'contain' }}
            />
            <CardContent>
                <Typography variant="h6" fontWeight="bold">
                    {product.title}
                </Typography>
                <Typography variant="body1" color="text.secondary" gutterBottom>
                    {product.description}
                </Typography>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                    <Typography variant="h5" color="error" fontWeight="bold">
                        ${discountPrice || price}
                    </Typography>
                    {discountPrice > 0 && (
                        <Typography variant="body2" color="text.secondary" sx={{ textDecoration: 'line-through' }}>
                            ${price}
                        </Typography>
                    )}
                </Box>

                <Chip
                    icon={<LocalOfferIcon fontSize="small" />}
                    label={`Only ${totalQuantity} left`}
                    size="small"
                    color="warning"
                    variant="outlined"
                />

                <Divider sx={{ my: 1 }} />

                {/* {product.attributes?.map((attribute) => (
                   <Box key={attribute._id} sx={{ mb: 2 }}>
                   <Typography variant="subtitle2" gutterBottom>
                       Choose a {attribute.name}:
                   </Typography>
                   <RadioGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                       {attribute.value.map((value) => (
                           <FormControlLabel
                               key={value}
                               value={value}
                               control={
                                   <Radio
                                       checked={selectedAttributes[attribute.name] === value}
                                       onChange={() => handleAttributeSelect(attribute.name, value)}
                                       color="primary"
                                   />
                               }
                               label={value}
                               sx={{ mr: 2 }}  // Optional: Add margin to the right for spacing between the buttons
                           />
                       ))}
                   </RadioGroup>
               </Box>
               
                ))} */}

               
            </CardContent>
        </Card>
    );
};

const ProductSellerGrid = ({ products }) => {
    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
            {products?.length > 0 && products.map((product) => (
                <ProductDisplayPanel key={product._id} product={product} />
            ))}
        </Box>
    );
};

export default ProductSellerGrid;
