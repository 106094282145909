import React, { useEffect, useRef, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { endLive, generateAgoraToken, goLive } from '../axios/api';
import { options, rtc } from '../constant';
import AgoraRTC from "agora-rtc-sdk-ng";
import { Box, Button, Typography, Container, IconButton, Grid2 } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import io from "socket.io-client";
import VisibilityIcon from '@mui/icons-material/Visibility';
import Lottie from "lottie-react";
import ProductSellerGrid from '../components/sellerProduct';
import goliveAnimation from "../components/lottie/live.json"

const Golive = () => {
  const { id } = useParams();
  const location = useLocation();
  const [token, setToken] = useState('');
  const [liveData, setLiveData] = useState();
  const [isCameraOn, setIsCameraOn] = useState(true);
  const [isMicOn, setIsMicOn] = useState(true);
  const [comments, setComments] = useState([]);
  const [numUsers, setNumUsers] = useState(0);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true)
  const commentsEndRef = useRef(null);

  const [showEndLiveButton, setShowEndLiveButton] = useState(false);


  const [socket, setSocket] = useState(null);
  useEffect(() => {
    if (liveData) {

      const socketConnection = io(process.env.REACT_APP_SOCKET_URL);
      setSocket(socketConnection)
      socketConnection.on("connect", () => {
        console.log("Connected to socket server");
        socketConnection.emit('join_stream', { streamId: id, userId: liveData.sellerId._id });
      });

      socketConnection.on("receive_comments", (comments) => {
        console.log("Received previous comments:", comments);
        setComments(comments);
      });

      socketConnection.on("user_count", (count) => {
        console.log("User count updated:", count);
        setNumUsers(count);
      });

      socketConnection.on("receive_comment", (newComment) => {
        console.log("Received new comment:", newComment);
        setComments((prevComments) => [...prevComments, newComment]);
      });

      return () => {
        console.log("Disconnecting from socket server...");
        socketConnection.disconnect();
      };
    }

  }, [id, liveData]);



  const fetchData = async (id) => {
    try {
      const response = await goLive(id);
      if (response.data) {
        setLiveData(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleGolive = async () => {
    if (!liveData || !liveData._id) {
      console.error("Missing live data or token");
      return;
    }
    try {
      if (rtc.client) {
        if (rtc.client.state === "CONNECTED" || rtc.client.state === "CONNECTING") {
          await rtc.client.leave();
        }
      }

      const response = await generateAgoraToken({ channelName: liveData._id, uid: liveData.sellerId._id, role: "publisher" });
      if (response.data) {
        await rtc.client.join(options.appId, liveData._id, response.data.token, liveData.sellerId._id);
        rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
        rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
        rtc.localVideoTrack.play("local-stream");
        await rtc.client.publish([rtc.localAudioTrack, rtc.localVideoTrack]);
        console.log("Publisher has started the stream!");
      }
    } catch (error) {
      console.error("Error while going live:", error);
    }
  };

  const toggleCamera = async () => {
    if (rtc.localVideoTrack) {
      await rtc.localVideoTrack.setEnabled(!isCameraOn);
      setIsCameraOn(!isCameraOn);
    }
  };

  const toggleMic = async () => {
    if (rtc.localAudioTrack) {
      const newMicStatus = !isMicOn;



      await rtc.localAudioTrack.setVolume(newMicStatus ? 100 : 0);
      // await rtc.localAudioTrack.setEnabled(newMicStatus);

      setIsMicOn(newMicStatus);

      console.log('Audio track muted status:', newMicStatus);
    }
  };
  const handleEndLive = async () => {
    try {
      const localContainer = document.getElementById("local-stream");
      if (localContainer) {
        localContainer.innerHTML = "";
      }

      if (rtc.localAudioTrack) {
        rtc.localAudioTrack.close();
      }
      if (rtc.localVideoTrack) {
        rtc.localVideoTrack.close();
      }

      rtc.client.remoteUsers.forEach((user) => {
        const playerContainer = document.getElementById(`remote-${user.uid}`);
        if (playerContainer) {
          playerContainer.remove();
        }
      });

      await rtc.client.leave();
      socket.emit('leave_stream', { streamId: id, userId: liveData.sellerId._id });
      console.log("Left the channel successfully");
      await endLive(id);
      // window.open(`${process.env.REACT_APP_FRONTENDLINK}/vendor/live-show`, '_blank');
      window.close()
    } catch (error) {
      console.error("Error leaving channel:", error);
    }
  };

  useEffect(() => {
    if (commentsEndRef.current) {
      commentsEndRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [comments]);


  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const tokenFromUrl = urlParams.get('token');
    if (tokenFromUrl) {
      localStorage.setItem("token", tokenFromUrl);
    }
    setToken(tokenFromUrl);
  }, [location.search]);

  useEffect(() => {
    if (id && token) {
      fetchData(id);
    }
  }, [id, token]);

  useEffect(() => {
    if (liveData) {
      rtc.client = AgoraRTC.createClient({ mode: "rtc", codec: "h264" });
      handleGolive();
    }
  }, [liveData]);

  useEffect(() => {
    if(loading && id && token && liveData ){
      setLoading(false)
    }
  },[loading , id ,token , liveData])


  const styles = {
    loaderContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh', 
      backgroundColor: 'black', 
      color: 'white',  
      textAlign: 'center', 
    },
    message: {
      marginTop: '20px',  
      fontSize: '20px', 
      fontWeight: 'bold',  
    }
  };
  


  if (loading) {
    return (
      <div style={styles.loaderContainer}>
        <Lottie 
          animationData={goliveAnimation} 
          loop={true}  // Set loop to true to keep playing
          autoplay={true}  // Ensures the animation starts automatically
          style={{ height: "200px" }} 
        />
         <p style={styles.message}>Starting Live Stream...</p>
      </div>
    );
  }



  return (
    <div style={{ width: "100%", height: "100vh", background: "black", display: "flex" }}>
      <Grid2 container spacing={1} style={{ width: "100%", height: "100%" }}>
        <Grid2 size={{ xs: false, sm: 4, md: 4 }}
          style={{
            backgroundColor: 'transparent',
            height: '100%',
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
          sx={{
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            scrollbarWidth: 'none',
          }}>
          <ProductSellerGrid products={liveData?.products} />
        </Grid2>
        <Grid2 className="live-admin-side" size={{ xs: 12, sm: 4, md: 4 }} style={{ display: "flex", justifyContent: "center", alignItems: "center", position: "relative" }}>
          <Container
            maxWidth="xs"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100vh',
              textAlign: 'center',
              paddingTop: '20px',
              backgroundColor: '#000',
            }}
          >
            <Box
              id="local-stream"
              sx={{
                width: '100%',
                height: '100%',
                aspectRatio: '16/9',
                backgroundColor: '#000',
                borderRadius: '8px',
                position: 'relative',
                overflow: 'hidden',
              }}
              onMouseEnter={() => setShowEndLiveButton(true)}
              onMouseLeave={() => setShowEndLiveButton(false)}
            >

              <div style={{
                position: "absolute",
                top: 10,
                left: 10,
                color: 'white',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: "999",
                borderRadius: "50px",
                '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.7)' }
              }}>
                <img src={liveData?.sellerId?.profileImage} alt='profile' height={45} width={45} style={{ borderRadius: "50px" }} />
              </div>

              <div
                style={{
                  position: "absolute",
                  top: 10,
                  right: 70,
                  color: 'white',
                  zIndex: "999",
                  background: "pink",
                  borderRadius: "50px",
                  padding: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.7)' },
                }}
              >
                <VisibilityIcon sx={{ color: "#e1306c", fontSize: "30px" }} />
                {numUsers && <span style={{ marginLeft: "5px", fontSize: "16px", color: "#333" }}>{numUsers - 1}</span>}
              </div>

              <IconButton
                onClick={toggleMic}
                sx={{
                  position: 'absolute',
                  top: 10,
                  right: 10,
                  color: 'white',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  zIndex: "999",
                  '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.7)' }
                }}
              >
                {isMicOn ? <MicIcon /> : <MicOffIcon />}
              </IconButton>

              <IconButton
                onClick={toggleCamera}
                sx={{
                  position: 'absolute',
                  top: 50,
                  right: 10,
                  color: 'white',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  zIndex: "999",
                  '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.7)' }
                }}
              >
                {isCameraOn ? <VideocamIcon /> : <VideocamOffIcon />}
              </IconButton>

              {showEndLiveButton && (
                <Button
                  onClick={handleEndLive}
                  variant="contained"
                  color="error"
                  sx={{
                    position: 'absolute',
                    bottom: 10,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    zIndex: 999,
                    backgroundColor: 'rgba(255, 0, 0, 0.7)',
                    '&:hover': { backgroundColor: 'rgba(255, 0, 0, 0.9)' }
                  }}
                >
                  End Live
                </Button>
              )}
            </Box>
            {/* </Grid2> */}

            <Typography variant="h6" sx={{ marginTop: 2, color: 'white' }}>
              {liveData ? `Streaming: ${liveData.title}` : "Waiting to start stream..."}
            </Typography>

          </Container>
        </Grid2>
        <Grid2
          size={{ xs: 12, sm: 4, md: 4 }}
          style={{
            height: '100vh',
            padding: '20px',
            overflowY: "auto",
            overflowX: 'hidden',
            backgroundColor: '#f4f4f4',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}
          sx={{
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            scrollbarWidth: 'none',
          }}
        >
          <div
            id="comments"
            style={{
              maxHeight: '80%',
              overflowY: 'scroll',
              width: '100%',
              marginBottom: '20px',
              flex: 1,
              paddingRight: '10px',
            }}
          >
            {comments.map((commentData, index) => (
              <div
                key={index}
                style={{
                  padding: '15px',
                  marginBottom: '10px',
                  backgroundColor: '#ffffff',
                  borderRadius: '8px',
                  border: '1px solid #ddd',
                  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                  maxWidth: '90%', 
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                <div
                  style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                    color: '#333',
                    marginBottom: '5px',
                  }}
                >
                  {commentData.user}
                </div>
                <p
                  style={{
                    fontSize: '15px',
                    lineHeight: '1.6',
                    color: '#555',
                    marginBottom: '10px',
                  }}
                >
                  {commentData.comment}
                </p>
              </div>
            ))}
            <div ref={commentsEndRef} />
          </div>
        </Grid2>

      </Grid2>
    </div>
  );
};

export default Golive;
