import axiosInstance from "./axios"

export const joinLive = (id) => {
    return axiosInstance.post(`/buyer/live/${id}`)
}
export const generateAgoraToken = (data) => {
    return axiosInstance.get("/seller/live-show/generate-agora-token", { params: data })
}
export const endLive = (id) => {
    return axiosInstance.post(`/seller/live-show/end-live/${id}`)
}
export const goLive = (id) => {
    return axiosInstance.post(`/seller/live-show/go-live/${id}`)
}
export const getBuyer = () => {
    return axiosInstance.get("/auth/buyer")
}
export const checkVariantExist = (payload) => {
    return axiosInstance.post("/users/check-variant", payload)
}
export const addToCart = (data) => {
    return axiosInstance.post("/buyer/cart", data)
}