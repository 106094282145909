// constants.js
export const options = {
    appId: "b8bde60565454ea1b8eca43c1e367656", 
  };
  
  export const rtc = {
    client: null,
    localAudioTrack: null,
    localVideoTrack: null,
  };
  