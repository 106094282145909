import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Button,
    Card,
    CardMedia,
    CardContent,
    Chip,
    Divider,
    RadioGroup,
    FormControlLabel,
    Radio,
    IconButton,
    TextField,
    Paper,
    Stack,
    CircularProgress,
    Snackbar,
    Alert
} from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { addToCart, checkVariantExist } from '../axios/api';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

const ProductDisplayPanel = ({ product }) => {
    const [selectedAttributes, setSelectedAttributes] = useState({});
    const [isVariantAvailable, setIsVariantAvailable] = useState(false);
    const [variantId, setVairantId] = useState("");
    const [price, setPrice] = useState(0);
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [discountPrice, setDiscountPrice] = useState(0);
    const [quantity, setQuantity] = useState(1);
    const [loading, setLoading] = useState(false)
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    // Add preset quantity values
    const presetQuantities = [5, 10];

    console.log(price, variantId);

    const handleAttributeSelect = (name, value) => {
        const newAttributes = { ...selectedAttributes, [name]: value };
        setSelectedAttributes(newAttributes);

        checkVariantAvailability(newAttributes);
    };

    const createVariantString = (attributes) => {
        let variantString = "";

        Object.keys(attributes).forEach((key, index) => {
            variantString += `${key}-${attributes[key]}`;

            if (index !== Object.keys(attributes).length - 1) {
                variantString += ",";
            }
        });

        return variantString;
    };

    const checkVariantAvailability = async (selectedAttributes) => {
        const variantString = createVariantString(selectedAttributes);
        const response = await checkVariantExist({ productId: product._id, variant: variantString });
        if (response.data.exists) {
            const variantIndex = product?.variants?.findIndex((val) => val._id === response?.data?.id);
            setPrice(product?.variants[variantIndex]?.price);
            setDiscountPrice(product?.variants[variantIndex]?.discountPrice);
            setIsVariantAvailable(response.data.exists);
            setVairantId(response.data.id);
            setTotalQuantity(product?.variants[variantIndex]?.quantity);
        } else {
            setIsVariantAvailable(false);
            setVairantId("");
            setTotalQuantity(0);
        }
    };

    const handleQuantityChange = (type) => {
        setQuantity(prev => {
            if (type === 'increase' && prev < totalQuantity) return prev + 1;
            if (type === 'decrease' && prev > 1) return prev - 1;
            return prev;
        });
    };

    const handleSetQuantity = (value) => {
        if (value >= 1 && value <= totalQuantity) {
            setQuantity(value);
        }
    };

    const handleAddToCart = async (id) => {
        setLoading(true)
        try {
            const response = await addToCart({ productId: id, quantity, variantId });
            if (response.status === 200) {
                setLoading(false)
                setSnackbarOpen(true)
            }
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    };

    const calculateTotal = () => {
        return ((discountPrice || price) * quantity).toFixed(2);
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    useEffect(() => {
        if (product?.type === "simple") {
            setPrice(Number(product?.price));
            setTotalQuantity(Number(product?.quantity) ?? 0);
            setDiscountPrice(Number(product?.discountPrice) ?? 0);
            setIsVariantAvailable(true);
        }
        if (product && product?.attributes && product?.attributes?.length > 0 && product?.variants && product?.variants?.length > 0 && product?.type === "variant") {
            const initialAttributes = {};

            product?.variants[0]?.variant?.forEach((variant) => {
                initialAttributes[variant.name] = variant.value;
            });
            setSelectedAttributes(initialAttributes);
            setVairantId(product?.variants[0]?._id);
            setPrice(product?.variants[0]?.price);
            setDiscountPrice(product?.variants[0]?.discountPrice);
            setTotalQuantity(product?.variants[0]?.quantity);
            setIsVariantAvailable(true);
        }
    }, [product]);

    return (
        <Card className='product-card-wapper' elevation={3} sx={{ m: 2, border: '1px solid #1976d2' }}>
            <CardMedia
                component="img"
                height="200"
                image={product.productImages[0] || "https://via.placeholder.com/300x200?text=Product+Image"}
                alt={product.title}
                sx={{ objectFit: 'contain' }}
            />
            <CardContent>
                <Typography variant="h6" fontWeight="bold" sx={{ color: '#1976d2' }}>
                    {product.title}
                </Typography>
                <Typography variant="body1" color="text.secondary" gutterBottom>
                    {product.description}
                </Typography>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                    <Typography variant="h5" color="black" fontWeight="bold">
                        ${discountPrice || price}
                    </Typography>
                    {discountPrice > 0 && (
                        <Typography variant="body2" color="text.secondary" sx={{ textDecoration: 'line-through' }}>
                            ${price}
                        </Typography>
                    )}
                </Box>

                <Chip
                    icon={<LocalOfferIcon fontSize="small" />}
                    label={`Only ${totalQuantity} left`}
                    size="small"
                    color="warning"
                    variant="outlined"
                />

                <Divider sx={{ my: 1 }} />

                {product.attributes?.map((attribute) => (
                    <Box key={attribute._id} sx={{ mb: 2 }}>
                        <Typography variant="subtitle2" gutterBottom>
                            Choose a {attribute.name}:
                        </Typography>
                        <RadioGroup sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 2 }}>
                            {attribute.value.map((value) => (
                                <FormControlLabel
                                    key={value}
                                    value={value}
                                    control={
                                        <Radio
                                            checked={selectedAttributes[attribute.name] === value}
                                            onChange={() => handleAttributeSelect(attribute.name, value)}
                                            color="primary"
                                        />
                                    }
                                    label={value}
                                    sx={{ display: 'flex', justifyContent: 'center' }}
                                />
                            ))}
                        </RadioGroup>
                    </Box>
                ))}

                {/* Updated Quantity Selector UI */}
                <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle2" gutterBottom fontWeight="bold">
                        Quantity:
                    </Typography>

                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Paper
                            variant="outlined"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '250px',
                                borderRadius: 1,
                                overflow: 'hidden',
                                p: 0
                            }}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                disableElevation
                                onClick={() => handleQuantityChange('decrease')}
                                disabled={quantity <= 1}
                                sx={{
                                    borderRadius: 0,
                                    p: 1.5,
                                    minWidth: '60px',
                                    backgroundColor: '#f0f0f0',
                                    color: '#1976d2',
                                    '&:hover': {
                                        backgroundColor: '#e0e0e0',
                                    },
                                    '&.Mui-disabled': {
                                        color: 'rgba(0, 0, 0, 0.26)',
                                    }
                                }}
                            >
                                <RemoveIcon />
                            </Button>

                            {/* Input field */}
                            <TextField
    value={quantity}
    variant="standard"
    size="small"
    InputProps={{
        disableUnderline: true,
        readOnly: true,
        sx: {
            textAlign: 'center',
            fontSize: '18px',
            fontWeight: 'medium',
            width: '100%',
            p: 0,
            textAlignLast: 'center', // Ensures text is centered
        }
    }}
    sx={{
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        '& .MuiInputBase-root': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
        }
    }}
/>


                            <Button
                                variant="contained"
                                color="primary"
                                disableElevation
                                onClick={() => handleQuantityChange('increase')}
                                disabled={quantity >= totalQuantity}
                                sx={{
                                    borderRadius: 0,
                                    p: 1.5,
                                    minWidth: '60px',
                                    backgroundColor: '#f0f0f0',
                                    color: '#1976d2',
                                    '&:hover': {
                                        backgroundColor: '#e0e0e0',
                                    },
                                    '&.Mui-disabled': {
                                        color: 'rgba(0, 0, 0, 0.26)',
                                    }
                                }}
                            >
                                <AddIcon />
                            </Button>
                        </Paper>

                        {/* Quick select buttons */}
                        <Stack direction="row" spacing={1}>
                            {presetQuantities.map(preset => (
                                <Button
                                    key={preset}
                                    variant="outlined"
                                    size="small"
                                    onClick={() => handleSetQuantity(preset)}
                                    disabled={preset > totalQuantity}
                                    sx={{
                                        minWidth: '40px',
                                        height: '40px',
                                        borderRadius: 1
                                    }}
                                >
                                    {preset}
                                </Button>
                            ))}
                        </Stack>
                    </Box>

                    {/* Total price update */}
                    <Paper
                        variant="outlined"
                        sx={{
                            mt: 1,
                            p: 1,
                            display: 'flex',
                            justifyContent: 'space-between',
                            bgcolor: '#f8f8f8',
                            borderRadius: 1
                        }}
                    >
                        <Typography variant="body2" color="text.secondary">
                            Total:
                        </Typography>
                        <Typography variant="body2" fontWeight="bold">
                            ${calculateTotal()}
                        </Typography>
                    </Paper>
                </Box>

                <Box sx={{ mt: 'auto' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        startIcon={!loading && <ShoppingCartIcon />}
                        size="large"
                        sx={{ mb: 1.5, borderRadius: '25px', py: 1.2 }}
                        disabled={!isVariantAvailable}
                        onClick={() => handleAddToCart(product._id)}
                    >
                        {loading ? <CircularProgress  color='white'/> : "ADD TO CART"}
                    </Button>

                    {/* <Button
                        variant="outlined"
                        color="primary"
                        fullWidth
                        startIcon={<FavoriteBorderIcon />}
                        sx={{ borderRadius: '25px' }}
                    >
                        Add to Wishlist
                    </Button> */}
                </Box>
            </CardContent>
            <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    Product added to cart!
                </Alert>
            </Snackbar>
        </Card>
    );
};

const ProductGrid = ({ products }) => {
    return (
        <Box className="display-products-wrapper" sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '100%' }}>
            {products?.length > 0 && products.map((product) => (
                <ProductDisplayPanel key={product._id} product={product} />
            ))}
        </Box>
    );
};

export default ProductGrid;